var maps = [];

$(document).ready(function() {

  AOS.init();

   $('.lazy').show().lazy();

   $('.showForm').click(function(){
        $('#form').addClass('active');
        $('body').addClass('locked');
   });

   $('#form a.close').click(function(){
        $('#form').removeClass('active');
        $('body').removeClass('locked');
   });

   $('input').iCheck({
     checkboxClass: 'icheckbox_square',
     radioClass: 'iradio_square',
     increaseArea: '20%' // optional
   });

   $('.photos').magnificPopup({
       type: 'image',
       delegate: 'a',
       gallery: {
           enabled: false,
           preload: [0,2]
       }
   });

   $('.reference').click(function(){
       $(this).find('a').first().trigger( "click" );
   });

   $('.reference').each(function() {
       $(this).magnificPopup({
           type: 'image',
           delegate: 'a',
           gallery: {
               enabled: true,
               preload: [0,2]
           }
       });
   });

  $("#head .mobileNav").click(function(){
      if($('#head .nav').hasClass('active')){
          $(this).removeClass('active');
          $("#head .mobileNav i").removeClass('icon-close').addClass('icon-menu');
          $('#head .nav').removeClass('active');
          $('body').removeClass('locked');
      }else{
          $(this).addClass('active');
          $("#head .mobileNav i").addClass('icon-close').removeClass('icon-menu');
          $('#head .nav').addClass('active');
          $('body').addClass('locked');
      }
  });

  var owl = $('.owl-carousel');

  owl.owlCarousel({
      loop: true,
      margin: 0,
      nav: false,
      items: 1,
      autoplay:true,

      autoplayTimeout:5000
  });


  renderMaps();
});

function renderMaps(){
    if($( "body" ).has( "#map" )){
        $.getScript( url+"/scripts/leaflet.js", function( data, textStatus, jqxhr ) {
            var LeafIcon = L.Icon.extend({
                options: {
                    iconSize:     [75, 100],
                    iconAnchor:   [37, 100]
                }
            });
            var icons  = new LeafIcon({iconUrl: url+'/img/map.svg'});

            zoom = 10;
            if($('#map').attr('data-zoom') != '')  zoom = parseInt($('#map').attr('data-zoom'));
            lat  = parseFloat($('#map').attr('data-lat'));
            lng  = parseFloat($('#map').attr('data-lng'));

        	var mbAttr = '',
        		mbUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoicm9jZXNtYW5pYyIsImEiOiJjam5hb2NzazQyZWxoM2tydWd0OHN5OThkIn0.2kz8ZH1-HH-TkOh1anDHDA';

        	var grayscale   = L.tileLayer(mbUrl, {id: 'mapbox/light-v9', attribution: mbAttr});

        	var map = L.map('map', {
        		center: [lat, lng],
        		zoom: zoom,
        		layers: [grayscale],
                scrollWheelZoom: false
        	});

            L.marker([lat, lng], {icon: icons}).addTo(map);

        });
    }
}
